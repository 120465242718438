import 'core-js/stable';

/* eslint-disable no-restricted-globals */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import serviceContainer from './services/serviceContainer';
import { appService } from './services/appService';
import OfficeEventService from './services/officeEventService';
import { localSettingsService } from './services/localSettingsService';
import { DynamicScriptService } from './services/dynamicScriptService';

//Application Startup: Get and initialize officeJs from settings & render application
localSettingsService.loadLocalSettings().then(() => {
    const officeJsLocation = localSettingsService.getLocalSettings().officeJsLocation;
    const officeJsSriHash = localSettingsService.getLocalSettings().officeJsSriHash;

    // MS nullify the history API as it doesnt work in IE10 based Excel (see discussion here https://github.com/OfficeDev/office-js/issues/429)
    // suggested workaround if thats not an issue is to cache and restore the API as seen here (https://stackoverflow.com/a/53662709)
    const historyCache = {
        replaceState: window.history.replaceState,
        pushState: window.history.pushState,
    };

    DynamicScriptService.loadExternalResource(officeJsLocation, officeJsSriHash).then(() => {
        // Office js deletes window.history.pushState and window.history.replaceState. Restore them
        window.history.replaceState = historyCache.replaceState;
        window.history.pushState = historyCache.pushState;

        Office.onReady().then(() => {
            const container = document.getElementById('root');
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const root = createRoot(container!);
            root.render(
                <Provider store={store}>
                    <Router>
                        <App />
                    </Router>
                </Provider>,
            );
        });
    });
});

const store = configureStore({
    readPaneReducer: {
        isLoading: false,
        error: null,
        loadingFieldCount: 0,
        classificationPaneViewModel: null,
    },
    selectClassificationReducer: {
        processing: false,
        processingMessage: null,
        error: null,
        classificationPaneViewModel: null,
        applicationSettings: null,
        suggestPreamble: null,
        themeColor: '',
    },
    alertsReducer: {
        processing: false,
        processingMessage: null,
        error: null,
        alerts: [],
        mode: undefined,
        canRemediateAll: false,
        remediated: false,
        isHostOutlook: false,
        themeColor: '',
        applicationSettings: null,
    },
});

initializeIcons();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const getGlobal = () => {
    return typeof self !== 'undefined'
        ? self
        : typeof window !== 'undefined'
          ? window
          : typeof global !== 'undefined'
            ? global
            : undefined;
};

const processOnSend = async (event: Office.AddinCommands.Event) => {
    await localSettingsService.loadLocalSettings();
    try {
        const officeEventService = new OfficeEventService(appService, serviceContainer);
        const allowOnSend = await officeEventService.evaluateOnSend();
        event.completed({ allowEvent: allowOnSend });
    } catch {
        event.completed({ allowEvent: true });
    }
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const glob = getGlobal() as any;

// the add-in command functions need to be available in global scope
glob.processOnSend = processOnSend;
