import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import BuiltInProperties from 'titus-ts/dist/js/Document/BuiltInProperties';
import { AddinApi } from './AddinApi';

export class ExcelApi extends AddinApi {
    public getBodyAsTextAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            Excel.run(async (context) => {
                const sheets: Excel.Range[] = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const data: any[] = [];
                const worksheets = context.workbook.worksheets;
                context.load(worksheets);
                await context.sync();
                if (!worksheets.items) reject('WorkSheet item can not be null or undefined');
                else {
                    worksheets.items.forEach((item) => {
                        const sheet = item.getUsedRange().load('values');
                        sheets.push(sheet);
                    });
                    await context.sync();
                    sheets.forEach((sheet) => {
                        const sheetData = sheet.values.reduce(
                            (currentValue, newValue) => currentValue.concat(newValue),
                            [],
                        );
                        data.push(sheetData);
                    });
                    resolve(data.toString());
                }
            });
        });
    };

    public getCustomProperties = (
        requestedCustomPropertiesNames: string[],
    ): Promise<DictionaryInterface<string | string[]>> => {
        return new Promise<DictionaryInterface<string | string[]>>((resolve, reject) => {
            Excel.run(async (context) => {
                const customProperties = context.workbook.properties.custom;
                const selectedCustomProperties: DictionaryInterface<string | string[]> = {};
                context.load(customProperties);
                await context.sync();
                if (!customProperties.items) reject('Excel get Custom Properties failed');
                else {
                    customProperties.items.forEach((item) => {
                        if (requestedCustomPropertiesNames.includes(item.key)) {
                            selectedCustomProperties[item.key] = item.value.split(';');
                        }
                    });
                }
                resolve(selectedCustomProperties);
            });
        });
    };

    public setCustomProperties(
        selectedCustomProperties: DictionaryInterface<string | string[]>,
        associatedCustomPropertiesNames: string[],
    ): Promise<void> {
        return new Promise<void>((resolve) => {
            Excel.run(async (context) => {
                const fields = Object.getOwnPropertyNames(selectedCustomProperties);
                const customProperties = context.workbook.properties.custom;
                context.load(customProperties);
                await context.sync();
                for (let i = 0; i < associatedCustomPropertiesNames.length; i++) {
                    customProperties.getItemOrNullObject(associatedCustomPropertiesNames[i])?.delete();
                }
                await context.sync();
                for (let i = 0; i < fields.length; i++) {
                    const valueString =
                        typeof selectedCustomProperties[fields[i]] == 'string'
                            ? selectedCustomProperties[fields[i]]
                            : (selectedCustomProperties[fields[i]] as string[]).join(';');
                    context.workbook.properties.custom.add(fields[i], valueString);
                }
                await context.sync();
                resolve();
            });
        });
    }

    public getProperties = (): Promise<BuiltInProperties> => {
        return new Promise<BuiltInProperties>((resolve, reject) => {
            Excel.run(async (context) => {
                const properties = context.workbook.properties;
                context.load(properties);
                await context.sync();
                if (!properties) reject('Excel get properties failed');
                else {
                    const docProperties: BuiltInProperties = {
                        Author: properties.author,
                        Category: properties.category,
                        Comments: properties.comments,
                        CreationDate: properties.creationDate?.toDateString(),
                        Keywords: properties.keywords,
                        LastAuthor: properties.lastAuthor,
                        LastSaveTime: '',
                        RevisionNumber: properties.revisionNumber?.toString(),
                        Subject: properties.subject,
                        Template: '',
                        Title: properties.title,
                        LastPrintDate: '',
                        NumberOfPages: '',
                    };
                    resolve(docProperties);
                }
            });
        });
    };

    public getHeaderContent = (): Promise<string> => {
        return new Promise((resolve) => {
            Excel.run(async (context) => {
                const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
                const headersAndFooters = await this.getHeadersAndFooters(context, activeWorksheet);
                const headerContent = headersAndFooters.centerHeader;
                resolve(headerContent);
            });
        });
    };

    public getFooterContent = (): Promise<string> => {
        return new Promise((resolve) => {
            Excel.run(async (context) => {
                const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
                const headersAndFooters = await this.getHeadersAndFooters(context, activeWorksheet);
                const footerContent = headersAndFooters.centerFooter;
                resolve(footerContent);
            });
        });
    };

    public setHeaderAndFooterAsync = async (location: 'header' | 'footer', headerFooterText: string): Promise<void> => {
        return new Promise((resolve) => {
            Excel.run(async (context) => {
                const worksheets = context.workbook.worksheets;
                context.load(worksheets);
                await context.sync();
                const worksheetsItems = worksheets.items;
                for (const worksheetItem of worksheetsItems) {
                    const headersAndFooters = await this.getHeadersAndFooters(context, worksheetItem);
                    if (location === 'header') {
                        headersAndFooters.set({ centerHeader: this.getPlainTextFromHtml(headerFooterText) });
                    } else if (location === 'footer') {
                        headersAndFooters.set({ centerFooter: this.getPlainTextFromHtml(headerFooterText) });
                    }
                    worksheetItem.getRange().format.autofitColumns();
                }
                await context.sync();
                resolve();
            });
        });
    };

    public getPlatform = (): string => {
        const platformType = this.getPlatformType();
        let platform: string;
        switch (platformType) {
            case 'Android':
                platform = 'ExcelAndroid';
                break;
            case 'iOS':
            case 'Mac':
                platform = 'ExcelIOS';
                break;
            case 'OfficeOnline':
                platform = 'ExcelWebApp';
                break;
            default:
                platform = 'Excel';
                break;
        }
        return platform;
    };

    private getHeadersAndFooters = (
        context: Excel.RequestContext,
        worksheet: Excel.Worksheet,
    ): Promise<Excel.HeaderFooter> => {
        return new Promise(async (resolve) => {
            const headerFooter = worksheet.pageLayout.headersFooters.defaultForAllPages;
            headerFooter.load({ $all: true });
            await context.sync();
            resolve(headerFooter);
        });
    };

    private getPlainTextFromHtml(html: string): string {
        const parser = new DOMParser();

        // Parse the HTML string into a DOM Document
        const doc = parser.parseFromString(html, 'text/html');

        return doc.body?.textContent || '';
    }
}
