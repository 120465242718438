import React from 'react';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import { Dropdown, IDropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ResponsiveMode } from '@fluentui/react/lib/ResponsiveMode';
import { DatePicker, IDatePicker, TooltipHost, defaultDatePickerStrings } from '@fluentui/react';
import Value from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Value';

type DateValuePickerProps = {
    field: Field;
    onChange: (fieldName: string, fieldValues: string | string[]) => void;
};

function FormatDateToYYYYMMDD(date: Date | undefined): string {
    date = date ? date : new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
function ParseDateFromYYYYMMDD(dateStr: string): Date | null {
    const datePartsStrings = dateStr.split('-');
    if (datePartsStrings.length != 3) {
        // string contains wrong number of elements or incorrect separator so invalid
        return null;
    }

    const dateParts: number[] = datePartsStrings.map((datePart) => Number.parseInt(datePart));
    if (dateParts.some((datePart) => isNaN(datePart))) {
        // one or more date parts aren't a number so invalid string
        return null;
    }
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]); // Month is zero-based, so -1
    return date ? date : null;
}
function GetSelectedValueNameAndDateFromValue(field: Field) {
    // if we dont have a selected value it can't be valid
    if (!field.selectedValue || field.selectedValue.length == 0) {
        return ['', ''];
    }

    const fieldContainsValues = field.values.length > 0;
    // Only 1 selectedValue is supported for date fields without values
    if (!fieldContainsValues && field.selectedValue.length != 1) {
        return ['', ''];
    }
    // Only 1 or 2 selectedValues are supported for date fields with values
    if (fieldContainsValues && field.selectedValue.length > 2) {
        return ['', ''];
    }

    // Next lets check if the value name is valid
    let selectedValueName: string;
    let selectedValue: Value | undefined;
    if (fieldContainsValues) {
        selectedValueName = field.selectedValue[0];
        selectedValue = field.values.find((value) => value.name == selectedValueName);

        if (!selectedValue) {
            return ['', ''];
        }
    } else {
        selectedValueName = '';
        selectedValue = undefined;
    }

    // finally lets get the date and verify it can be parsed
    let selectedDateString: string;
    if (fieldContainsValues) {
        if (field.selectedValue.length == 1) {
            // we dont have a date yet so we need to set one
            const selectedDate = new Date();
            if (selectedValue?.offsetInDays) {
                selectedDate.setDate(selectedDate.getDate() + selectedValue.offsetInDays);
            }
            selectedDateString = FormatDateToYYYYMMDD(selectedDate);
        } else {
            // we have a value name and a date so get the second value
            selectedDateString = field.selectedValue[1];
        }
    } else {
        // we just have a date set so grab it
        selectedDateString = field.selectedValue[0];
    }

    if (!Date.parse(selectedDateString)) {
        return ['', ''];
    }

    return [selectedValueName, selectedDateString as string];
}

// Custom renderer for field values (options) within fluent UI dropdown
export const _onRenderOption = (option: IDropdownOption) => {
    const hasToolTip = option.data && option.data.tooltip;
    const noTooltip = !option.data || !option.data.tooltip;
    return (
        <div
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'noWrap',
            }}
        >
            {hasToolTip && (
                <TooltipHost id={option.key + '_' + option.text} content={option.data.tooltip}>
                    <span>{option.text}</span>
                </TooltipHost>
            )}
            {noTooltip && <span>{option.text}</span>}
        </div>
    );
};

// Custom renderer for selected value (option) within fluent UI dropdown
export const _onRenderTitle = (options: IDropdownOption[]) => {
    return _onRenderOption(options[0]);
};

const ValuePickerDate = (props: DateValuePickerProps) => {
    const { field, onChange } = props;

    const options: IDropdownOption[] = field.values.map((val) => ({
        key: val.name,
        text: val.displayText,
        data: {
            tooltip: val.tooltip,
            offsetInDays: val.offsetInDays,
        },
        title: '', //Title is used by MSFT as Tooltip, if not assigned any value item.text is used by default.
    }));
    const datePickerRef = React.useRef<IDatePicker>(null);
    const dropdownRef = React.useRef<IDropdown>(null);

    let [selectedValueName, selectedDateString] = GetSelectedValueNameAndDateFromValue(field);
    let selectedValue = selectedValueName ? field.values.find((value) => value.name == selectedValueName) : null;
    let selectedDate = ParseDateFromYYYYMMDD(selectedDateString);
    selectedDate = selectedDate || new Date();
    let allowCustomDate = field.values.length == 0 || selectedValue?.offsetInDays == 0;

    const _onDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        selectedValueName = item ? (item.key as string) : '';
        selectedValue = field.values.find((value) => value.name == selectedValueName);
        allowCustomDate = selectedValue?.offsetInDays == 0;

        selectedDate = new Date();
        if (selectedValue?.offsetInDays) {
            selectedDate.setDate(selectedDate.getDate() + selectedValue.offsetInDays);
        }
        selectedDateString = FormatDateToYYYYMMDD(selectedDate);

        onChange(field.name, [selectedValueName, selectedDateString]);
    };
    const _onDateChange = (date: Date | undefined | null): void => {
        selectedDate = date ? date : new Date();
        selectedDateString = FormatDateToYYYYMMDD(selectedDate);

        onChange(field.name, [selectedValueName, selectedDateString]);
    };

    return (
        <div data-testid="date_valuepicker">
            {field.values.length > 0 && (
                <Dropdown
                    title={field.name + '_ValuePicker'}
                    componentRef={dropdownRef}
                    responsiveMode={ResponsiveMode.large}
                    options={options}
                    selectedKey={selectedValueName}
                    onChange={_onDropdownChange}
                    // Due to error in FabricUI Typescript definition file, we have to cast function as any
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onRenderOption={_onRenderOption as any}
                    // Due to error in FabricUI Typescript definition file, we have to cast function as any
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onRenderTitle={_onRenderTitle as any}
                />
            )}
            <DatePicker
                title={field.name + '_DatePicker'}
                componentRef={datePickerRef}
                allowTextInput
                ariaLabel="Select a date"
                disabled={!allowCustomDate}
                value={selectedDate}
                onSelectDate={_onDateChange}
                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                strings={defaultDatePickerStrings}
            />
        </div>
    );
};

export default ValuePickerDate;
